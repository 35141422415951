<template>
  <div class="card pt-5 pb-5">
    <validation-observer ref="changePassword">
      <b-form class="row">
        <div class="col-md-10 col-sm-12 m-auto">
          <div class="row">
            <div class="col-12">
              <h3 class="mb-1">
                {{ $t('Keep your account Secure!') }}
              </h3>
              <h3 class="mb-2">
                {{ $t('Update your Password') }}
              </h3>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <b-form-group label-for="old_password" :label="$t('Old password')">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Old password')"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="old_password"
                      v-model="formData.old_password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger">{{ wrongPassword }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <b-form-group label-for="password" :label="$t('New password')">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('New password')"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="formData.new_password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <!-- password confirmation-->
              <b-form-group label-for="password-confirmation" :label="$t('Password Confirmation')">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Password Confirmation')"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password-confirmation"
                      v-model="formData.confirm_password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <b-button
                class="mr-2 footer-btn"
                variant="primary"
                type="submit"
                :disabled="submitLoader"
                @click.prevent="validationForm()"
              >
                {{ $t('Save Changes') }}
              </b-button>

              <b-button
                class="footer-btn"
                :to="{ name: 'edit-my-profile' }"
                type="submit"
                variant="outline-primary"
                :class="formData.published === 0 ? 'ml-2' : ''"
                :disabled="submitLoader"
                @click.prevent
              >
                {{ $t('Back') }}
              </b-button>
            </div>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@/libs/validations'
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@/mixins/togglePasswordVisibility'
import MyProfileService from '@/services/MyProfileService'

export default {
  name: 'ChangePassword',
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      MyProfileService: new MyProfileService(),
      submitLoader: false,
      formData: {
        old_password: '',
        new_password: '',
        confirm_password: '',
      },
      required,
      wrongPassword: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    validationForm() {
      this.$refs.changePassword.validate().then(success => {
        if (success) {
          this.submitLoader = true
          this.wrongPassword = ''
          this.MyProfileService.changePassword(this.formData).then(
            res => {
              this.submitLoader = false
              this.$store.commit('auth/setAccessToken', res.user.access_token)
              this.$router.push({ name: 'edit-my-profile' })
            },
            error => {
              console.log(error)
              this.submitLoader = false
              if (error.status_code === 400) {
                this.wrongPassword = error.message
              }
            }
          )
        }
      })
    },
  },
}
</script>

<style scoped></style>
